import React, {useEffect, useState} from "react";
import {
    Card,
    Center,
    Grid,
    Image,
    LoadingOverlay,
    Text,
} from "@mantine/core";
import {useStyles} from "../../util/CustomStyles";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {CakeShapes, DoughTypes, MessageTypes, PickupLocations} from "../../configs/CakeConstants";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {OrderSummary} from "../../components/orderSummary";

export const Order = () => {
    const {classes, cx} = useStyles();
    const {id} = useParams()
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigate('/login');
        }
        axiosRequest.get('orders/' + id).then((response) => {
            setOrder(response.data)
            setLoading(false);
        }).catch((error) => {
            navigate('/orders');
        })
    }, [])


    return (
        <div style={{position: "relative"}}>
            {
                !loading ?
                    <Center sx={classes.full}>
                        <Card
                            shadow={"sm"}
                            padding={"md"}
                            sx={classes.w90}
                        >
                            <Text
                                size={"lg"}
                                transform={"uppercase"}
                                weight={700}
                            >
                                {"Comanda " + id}
                            </Text>
                            <Grid>
                                <Grid.Col span={12}>
                                    <OrderSummary order={order}/>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        Semnatură:
                                    </Text>
                                </Grid.Col>
                                <Grid.Col md={3}/>
                                <Grid.Col md={6}>
                                    <Image src={order?.signature}/>
                                </Grid.Col>
                                <Grid.Col md={3}/>
                            </Grid>
                        </Card>
                    </Center>
                    : <LoadingOverlay visible={loading}/>
            }
        </div>
    )
}
