import React, {useEffect, useState} from "react";
import {Button, Card, Center, Grid, Input, Text, TextInput} from "@mantine/core";
import {useStyles} from "../../util/CustomStyles";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";

export const Login = () => {
    const {classes, cx} = useStyles();

    let navigate = useNavigate();

    const [buttonLoading, setButtonLoading] = useState(false);
    const [email, setEmail] = useState();
    const [emailErrors, setEmailErrors] = useState();
    const [password, setPassword] = useState();
    const [passwordErrors, setPasswordErrors] = useState();
    const [loginErrors, setLoginErrors] = useState();

    useEffect(() => {
        if(localStorage.getItem('user')){
            navigate('/orders');
        }
    }, [])

    const validate = () => {
        setEmailErrors("");
        setPasswordErrors("");
        setLoginErrors("");
        if (!email || !email.length) {
            setEmailErrors("Email-ul este obligatoriu!")
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailErrors("Email-ul trebuie să fie valid!")
            return false;
        }
        if (!password || !password.length) {
            setPasswordErrors("Parola este obligatorie!")
            return false;
        }
        return true;
    }

    const submitForm = () => {
        if (!validate())
            return;
        axiosRequest.post('/login', {
            email: email,
            password: password,
        }).then((response) => {
            setButtonLoading(false);
            localStorage.setItem('user', JSON.stringify(response.data))
            navigate("/orders")
        }).catch((error) => {
            setButtonLoading(false);
            setLoginErrors("Combinația email-parolă este invalidă!")
        })
    }

    return (
        <Center sx={classes.full}>
            <Card
                shadow={"sm"}
                padding={"md"}
                sx={classes.successCard}
            >
                <Text
                    size={"xl"}
                    align={"center"}
                >
                    Autentificare
                </Text>
                <Center
                    mt={10}
                >
                    <Grid>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Email:</Text>
                            <Input.Wrapper
                                id="email"
                                required
                                onChange={(event) => setEmail(event.target.value)}
                                error={emailErrors}
                            >
                                <TextInput id="email" value={email}/>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Parolă:</Text>
                            <Input.Wrapper
                                id="password"
                                required
                                onChange={(event) => setPassword(event.target.value)}
                                error={passwordErrors}
                            >
                                <TextInput id="password" value={password} type={"password"}/>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Center>
                                <Input.Wrapper
                                    error={loginErrors}
                                >
                                    <Center>
                                        <Button
                                            variant="outline"
                                            color="cyan"
                                            radius="lg"
                                            size="md"
                                            uppercase
                                            onClick={submitForm}
                                            loading={buttonLoading}
                                        >
                                            Conectează-te
                                        </Button>
                                    </Center>
                                </Input.Wrapper>
                            </Center>
                        </Grid.Col>
                    </Grid>
                </Center>
            </Card>
        </Center>
    )
}
