import React, {useEffect, useMemo, useState} from "react";
import {Button, Grid, Group, Input, NumberInput, Select, Text, TextInput} from "@mantine/core";
import {
    CAKE_TYPE_ID,
    DELIVERY_ID,
    ProductStructure
} from "../../configs/CakeConstants";
import {ProductCard} from "../productCard";
import {CalendarComponent} from "../calendar";
import {HourPicker} from "../hourPicker";
import {useSelector} from "react-redux";
import dayjs from "dayjs";

export const CakeDetailsForm = (props) => {
    const [loading, setLoading] = useState(true);

    const [products, setProducts] = useState([ProductStructure]);
    const [editableId, setEditableId] = useState(0);

    const [client, setClient] = useState();
    const [clientErrors, setClientErrors] = useState("");

    const [pickupLocation, setPickupLocation] = useState();
    const [pickupLocationErrors, setPickupLocationErrors] = useState("");
    const [address, setAddress] = useState("");

    const [phone, setPhone] = useState();
    const [phoneErrors, setPhoneErrors] = useState("")

    const [orderErrors, setOrderErrors] = useState("");

    const [prepay, setPrepay] = useState();

    const [selectedInterval, setSelectedInterval] = useState();
    const [selectedDay, setSelectedDay] = useState();

    const orderTypes = useSelector((state) => state.orderTypes)
    const pickupLocations = useSelector((state) => state.pickupLocations)

    const updateProduct = (product, id) => {
        let newProducts = products.slice();
        newProducts[id] = product;
        setProducts(newProducts);
        setEditableId(null);
    }

    const deleteProduct = (id) => {
        let newProducts = products.slice();
        newProducts.splice(id, 1);
        setEditableId(null);
        setProducts(newProducts);
    }

    const addProduct = () => {
        let newProducts = products.slice();
        newProducts.push(ProductStructure);
        setEditableId(products.length);
        setProducts(newProducts);
    }

    const nextStep = () => {
        if (!validateSecondStep())
            return;

        let day = dayjs(selectedDay);
        let date = dayjs(day.format('YYYY-MM-DD ') + selectedInterval, "YYYY-MM-DD HH:mm")

        props.setOrder({
            client: client,
            phone: phone,
            hour: date.format('YYYY-MM-DD HH:mm'),
            pickup_location_id: pickupLocation,
            address: address,
            prepay: prepay,
            products: products
        })
    }

    const validateSecondStep = () => {
        setClientErrors("");
        setPhoneErrors("");
        setPickupLocationErrors("");
        setOrderErrors("");
        if (!client || !client.length) {
            setClientErrors("Numele clientului este obligatoriu.")
            return false;
        }
        if (client.length < 3) {
            setClientErrors("Numele clientului trebuie să aibă cel puțin 3 caractere.")
            return false;
        }
        if (!phone || !phone.length) {
            setPhoneErrors("Numărul de telefon al clientului este obligatoriu!")
            return false;
        }
        if (!/^\d+$/.test(phone)) {
            setPhoneErrors("Numărul de telefon poate contine doar cifre!")
            return false;
        }
        if (!pickupLocation) {
            setPickupLocationErrors("Locul ridicării comenzii este obligatoriu!")
            return false;
        }
        if (products[0].product_type_id === CAKE_TYPE_ID && (!products[0].order_type_id || !products[0].dough_type_id || !products[0].shape_id)) {
            setOrderErrors("Trebuie adăugat minim un produs!")
            return false;
        }
        if (!selectedInterval) {
            setOrderErrors("Trebuie selectat un interval orar!")
            return false;
        }
        return true;
    }


    return (
        <Grid grow gutter={"lg"} mt={"4"}>
            <Grid.Col
                span={12}
            >
                <Text>Nume client</Text>
                <Input.Wrapper
                    id="client"
                    required
                    onChange={(event) => setClient(event.target.value)}
                    error={clientErrors}
                >
                    <TextInput id="client" value={client}/>
                </Input.Wrapper>
            </Grid.Col>
            <Grid.Col
                span={12}
            >
                <Text>Numar telefon client</Text>
                <Input.Wrapper
                    id="phone"
                    required
                    onChange={(event) => setPhone(event.target.value)}
                    error={phoneErrors}
                >
                    <TextInput id="phone" value={phone}/>
                </Input.Wrapper>
            </Grid.Col>
            <Grid.Col
                span={12}
            >
                <Text>Locul ridicării comenzii</Text>
                <Select
                    id="orderType"
                    data={pickupLocations}
                    value={pickupLocation}
                    error={pickupLocationErrors}
                    onChange={(value) => {
                        if (value != DELIVERY_ID) {
                            setAddress("");
                        }
                        setPickupLocation(value);
                    }}
                />
            </Grid.Col>
            <Grid.Col
                span={12}
            >
                <Text>Adresă livrare</Text>
                <Input.Wrapper
                    id="address"
                    onChange={(event) => setAddress(event.target.value)}
                >
                    <TextInput
                        id="address"
                        value={address}
                        disabled={pickupLocation != DELIVERY_ID}
                    />
                </Input.Wrapper>
            </Grid.Col>
            <Grid.Col
                span={12}
            >
                <Text>Avans (lei)</Text>
                <NumberInput
                    id="prepay"
                    value={prepay}
                    precision={2}
                    step={0.5}
                    min={0}
                    decimalSeparator=","
                    onChange={setPrepay}
                />
            </Grid.Col>
            {
                products.map((data, index) =>
                    <Grid.Col span={12} key={index}>
                        <ProductCard
                            product={data}
                            index={index}
                            orderTypes={orderTypes}
                            editableId={editableId}
                            setEditable={() => setEditableId(index)}
                            updateProduct={(product) => updateProduct(product, index)}
                            delete={() => deleteProduct(index)}
                        />
                    </Grid.Col>
                )
            }
            <Grid.Col span={12}>
                <Button onClick={addProduct} style={{float: "right"}} disabled={editableId != null}>
                    Adauga produs
                </Button>
            </Grid.Col>
            <Grid.Col
                md={8}
            >
                <CalendarComponent
                    selectedOrderType={products[0].order_type_id}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                />
            </Grid.Col>
            <Grid.Col
                md={4}
            >
                <HourPicker
                    selectedOrderType={products[0].order_type_id}
                    selectedDay={selectedDay}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Input.Wrapper
                    error={orderErrors}
                >
                    <Group position="center" mt="xl">
                        <Button onClick={nextStep}>Următorul pas</Button>
                    </Group>
                </Input.Wrapper>
            </Grid.Col>
        </Grid>
    )
}
