import {
    FETCH_DOUGH_TYPES_SUCCESS, FETCH_MESSAGE_TYPES_SUCCESS, FETCH_ORDER_TYPES_SUCCESS,
    FETCH_PICKUP_LOCATIONS_SUCCESS, FETCH_PRODUCT_TYPES_SUCCESS,
    FETCH_SHAPES_SUCCESS
} from "../constants/CakeConstants";


const initialState = {
    shapes: [],
    doughTypes: [],
    messageTypes: [],
    pickupLocations: [],
    orderTypes: [],
    productTypes: [],
}


const cakeConstants = (state = initialState , action) => {
    switch(action.type) {
        case FETCH_SHAPES_SUCCESS:
            return {
                ...state,
                shapes: action.payload
            }
        case FETCH_DOUGH_TYPES_SUCCESS:
            return {
                ...state,
                doughTypes: action.payload,
            }
        case FETCH_PICKUP_LOCATIONS_SUCCESS:
            return {
                ...state,
                pickupLocations: action.payload,
            }
        case FETCH_MESSAGE_TYPES_SUCCESS:
            return {
                ...state,
                messageTypes: action.payload,
            }
        case FETCH_ORDER_TYPES_SUCCESS:
            return {
                ...state,
                orderTypes: action.payload,
            }
        case FETCH_PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                productTypes: action.payload,
            }
        default:
            return state
    }
}

export default cakeConstants
