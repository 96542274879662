import React from "react";
import {ColorSwatch, Grid, Group, Space, Text} from "@mantine/core";
import {useMantineTheme} from "@mantine/styles";

/*
 * Component that acts as the Legend of the Calendar
 */
export const CalendarLegend = () => {
    const theme = useMantineTheme();

    const legend = [
        {
            color: theme.colors.yellow[6],
            text: "Dată parțial ocupată"
        },
        {
            color: theme.colors.red[6],
            text: "Dată complet ocupată"
        },
        {
            color: theme.colors.cyan[6],
            text: "Dată selectată"
        },
    ]

    return (
        <Grid mt={10}>
            {
                legend.map((data, index) =>
                    <Grid.Col md={12 / legend.length}>
                        <Group>
                            <ColorSwatch
                                color={data.color}
                            />
                            <Space w={"md"}/>
                            <Text>{data.text}</Text>
                        </Group>
                    </Grid.Col>
                )
            }
        </Grid>
    )
}
