import React, {useEffect, useState} from "react";
import {Box, Button, Center, LoadingOverlay} from "@mantine/core";
import {Link} from "react-router-dom";
import {useStyles} from "../../util/CustomStyles";

/*
 * Home page only acts as a node for other pages
 */
export const Home = () => {
    const [loading, setLoading] = useState(true);
    const {classes} = useStyles();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }, [])

    return (
        <Center className={classes.full}>
            <Box component={"div"} className={classes.relative}>
                <LoadingOverlay visible={loading}/>
                <Link to={"/add-order"}>
                    <Button color="cyan" radius="lg" size="xl" uppercase>
                        Adaugă o comandă nouă
                    </Button>
                </Link>
            </Box>
        </Center>

    )
}

