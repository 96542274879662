import React from "react";
import {Button} from "@mantine/core";
import {Link} from "react-router-dom";

export const UserMenu = () => {

    return (
        <>
            <Link to='/home'>
                <Button variant="outline" color="cyan" radius="lg" size="md" uppercase>
                    Acasă
                </Button>
            </Link>
            <Link to='/login'>
                <Button variant="outline" color="cyan" radius="lg" size="md" ml={5} uppercase>
                    Administrator
                </Button>
            </Link>
        </>
    )
}
