import React, {useEffect, useState} from "react";
import {
    Card,
    Center,
    Grid,
    Image,
    LoadingOverlay,
    Text,
} from "@mantine/core";
import {useStyles} from "../../util/CustomStyles";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {
    CAKE_TYPE_ID,
    CakeShapes,
    DELIVERY_ID,
    DoughTypes,
    MessageTypes,
    PickupLocations
} from "../../configs/CakeConstants";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {ProductCard} from "../productCard";

export const OrderSummary = (props) => {
    const {classes, cx} = useStyles();
    const {id} = useParams()
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(props.order);

    const pickupLocations = useSelector((state) => state.pickupLocations)
    const orderTypes = useSelector((state) => state.orderTypes)
    const shapes = useSelector((state) => state.shapes)
    const messageTypes = useSelector((state) => state.messageTypes)
    const doughTypes = useSelector((state) => state.doughTypes)
    const productTypes = useSelector((state) => state.productTypes)

    useEffect(() => {
        if (props.order) {
            setOrder(order)
            return;
        }

        if (!localStorage.getItem('user')) {
            navigate('/login');
        }
        axiosRequest.get('orders/' + id).then((response) => {
            setOrder(response.data)
            setLoading(false);
        }).catch((error) => {
            navigate('/orders');
            console.log(error);
        })
    }, [props.order])


    return (
        <Grid>
            <Grid.Col lg={4} sm={6}>
                <Text
                    size={"md"}
                    weight={500}
                >
                    {"Client: " + order?.client}
                </Text>
            </Grid.Col>
            <Grid.Col lg={4} sm={6}>
                <Text
                    size={"md"}
                    weight={500}
                >
                    {"Telefon: " + (order?.phone ?? "-")}
                </Text>
            </Grid.Col>
            <Grid.Col lg={4} sm={6}>
                <Text
                    size={"md"}
                    weight={500}
                >
                    {"Data: " + dayjs(order?.hour).format('DD-MM-YYYY HH:mm')}
                </Text>
            </Grid.Col>
            <Grid.Col lg={4} sm={6}>
                <Text
                    size={"md"}
                    weight={500}
                >
                    {"Locație ridicare: " + (order?.pickup_location_id == DELIVERY_ID ? order?.address : (pickupLocations.find(location => location.value === order?.pickup_location_id)?.label ?? "-"))}
                </Text>
            </Grid.Col>
            <Grid.Col lg={4} sm={6}>
                <Text
                    size={"md"}
                    weight={500}
                >
                    {"Avans: " + (order.prepay ? order.prepay + "(lei)" : "-")}
                </Text>
            </Grid.Col>
            {
                order.products.map((product, index) =>
                    <Grid.Col lg={12} key={index}>
                        <Card shadow={"sm"} p={"lg"} mb={10}>
                            <Text
                                size={"lg"}
                                transform={"uppercase"}
                                weight={700}
                            >
                                {"Produs " + (index + 1)}
                            </Text>
                            <Grid>
                                <Grid.Col lg={4} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Nume: " + product.name}
                                    </Text>
                                </Grid.Col>
                                <Grid.Col lg={4} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Tip: " + productTypes.find(type => type.value == product.product_type_id).label ?? "-"}
                                    </Text>
                                </Grid.Col>
                                {
                                    product.product_type_id == CAKE_TYPE_ID ?
                                        <>
                                            <Grid.Col lg={4} sm={6}>
                                                <Text
                                                    size={"md"}
                                                    weight={500}
                                                >
                                                    {"Tip tort: " + (orderTypes.find(orderType => orderType.value == product.order_type_id)?.label ?? "-")}
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col lg={4} sm={6}>
                                                <Text
                                                    size={"md"}
                                                    weight={500}
                                                >
                                                    {"Blat: " + (doughTypes.find(dough => dough.value == product.dough_type_id)?.label ?? "-")}
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col lg={4} sm={6}>
                                                <Text
                                                    size={"md"}
                                                    weight={500}
                                                >
                                                    {"Forma: " + (shapes.find(shape => shape.value == product.shape_id)?.label ?? "-")}
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col lg={4} sm={6}>
                                                <Text
                                                    size={"md"}
                                                    weight={500}
                                                >
                                                    {"Mesaj: " + product.message ?? "-"}
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col lg={4} sm={6}>
                                                <Text
                                                    size={"md"}
                                                    weight={500}
                                                >
                                                    {"Tip mesaj: " + (messageTypes.find(messageType => messageType.value == product.message_type_id)?.label ?? "Fară")}
                                                </Text>
                                            </Grid.Col>
                                        </>
                                        :
                                        <Grid.Col lg={4} sm={6}>
                                            <Text
                                                size={"md"}
                                                weight={500}
                                            >
                                                {"Cantitate per platou: " + (product.quantity_per_plate ? product.quantity_per_plate + "(kg)" : "-")}
                                            </Text>
                                        </Grid.Col>
                                }
                                <Grid.Col lg={4} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Pret: " + (product.price ? product.price + "(lei)" : "-")}
                                    </Text>
                                </Grid.Col>
                                <Grid.Col lg={4} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Cantitate: " + (product.quantity ? product.quantity + "(kg)" : "-")}
                                    </Text>
                                </Grid.Col>
                                <Grid.Col lg={6} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Alte observații: " + (product.details ?? "-")}
                                    </Text>
                                </Grid.Col>
                                <Grid.Col lg={12} sm={12}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >Imagine:</Text>
                                    { product.image ? <Image src={product.image} height={400} fit="contain"/> : "" }
                                </Grid.Col>
                            </Grid>
                        </Card>
                    </Grid.Col>
                )
            }
        </Grid>
    )
}
