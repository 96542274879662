export const DELIVERY_ID = 7;

export const PLATE_ORDER_TYPE_ID = "4";

export const CAKE_TYPE_ID = "1";

export const ProductStructure = {
    name: "",
    product_type_id: CAKE_TYPE_ID,
    order_type_id: "",
    dough_type_id: "",
    shape_id: "",
    message: "",
    message_type_id: "",
    price: null,
    quantity: null,
    quantity_per_plate: null,
    details: "",
    image: "",
}

