import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {DEFAULT_REDIRECT} from "./configs/RouteConfig";
import {Home} from "./views/home";
import {AddOrder} from "./views/addOrder";
import {AppShell} from "@mantine/core";
import {HeaderComponent} from "./components/header";

// Import Romanian language
import 'dayjs/locale/ro';
import {OrderSuccess} from "./views/orderSuccess";
import {Login} from "./views/login";
import {Orders} from "./views/orders";
import {Order} from "./views/order";

/*
 * Main Acces point of the router of the app.
 */
function App() {
  return (
      <Router>
        <AppShell
            padding="md"
            header={<HeaderComponent/>}
            styles={(theme) => ({
              main: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
              },
            })}
        >
          <Routes>
            <Route
                path="/home"
                element={<Home/>}
            />
            <Route
                path="/login"
                element={<Login/>}
            />
            <Route
                path="/orders"
                exact
                element={<Orders/>}
            />
            <Route
                path="/orders/:id"
                element={<Order/>}
            />
            <Route
                path="/add-order"
                element={<AddOrder/>}
            />
            <Route
                path="/order-success"
                element={<OrderSuccess/>}
            />
            <Route
                path="*"
                element={<Navigate replace to={DEFAULT_REDIRECT}/>}
            />
          </Routes>
        </AppShell>
      </Router>
  );
}

export default App;
