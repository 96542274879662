import React, {useEffect, useState} from "react";
import {Box, Button, LoadingOverlay, ScrollArea, Text} from "@mantine/core";
import axiosRequest from "../../configs/Axios";
import dayjs from "dayjs";
import {useStyles} from "../../util/CustomStyles";

/*
 * HourPicker Component is used in the AddOrder Page
 */
export const HourPicker = (props) => {

    const [intervals, setIntervals] = useState([]);
    const [loading, setLoading] = useState(false);
    const {classes, cx} = useStyles();

    /*
     * After each change of date, recheck the avilability for each interval
     */
    useEffect(() => {
        if (!props.selectedDay)
            return;
        setLoading(true);
        props.setSelectedInterval(null);
        const day = dayjs(props.selectedDay)
        axiosRequest.get('/order-types/' + props.selectedOrderType + '/day-capacity', {
            params: {
                filters: {
                    columns: ['year', 'month', 'day', 'order_type_id'],
                    operations: ['=', '=', '=', '='],
                    values: [day.year(), day.month() + 1, day.date(), props.selectedOrderType]
                },
                orderByColumns: {
                    columns: ['hour'],
                    directions: ['asc']
                },
            }
        }).then((response) => {
            setIntervals(response.data)
        }).catch((error) => {
            console.log(error);
        })
        setLoading(false);
    }, [props.selectedDay, props.selectedOrderType])

    /*
     * Based on the availability, the intervals have different colors
     */
    const getIntervalColor = (availability) => {
        if (availability === 0)
            return "red";
        if (availability > 0)
            return "yellow";
        return "cyan"
    }

    return (
        <Box component={"div"} sx={classes.relative}>
            <LoadingOverlay visible={loading} />
            <div>
                <Text
                    size={"md"}
                >
                    Alege o oră:
                </Text>
                {
                    intervals.length && props.selectedDay ?
                        <ScrollArea classNames={{root: classes.scrollBarRoot}} type={"always"}>
                            {
                                intervals.map((data, index) =>
                                    <Button
                                        variant={data.hour === props.selectedInterval ? "filled" : "subtle"}
                                        color={getIntervalColor(data.availability)}
                                        onClick={() => props.setSelectedInterval(data.hour)}
                                        disabled={data.availability === 0}
                                        size="lg"
                                        fullWidth
                                    >
                                        {data.hour}
                                    </Button>
                                )
                            }
                        </ScrollArea>
                        : ""
                }
            </div>
        </Box>
    )
}

