import {
    FETCH_DOUGH_TYPES_SUCCESS, FETCH_MESSAGE_TYPES_SUCCESS, FETCH_ORDER_TYPES_SUCCESS,
    FETCH_PICKUP_LOCATIONS_SUCCESS, FETCH_PRODUCT_TYPES_SUCCESS,
    FETCH_SHAPES_SUCCESS
} from "../constants/CakeConstants";
import axiosRequest from "../../configs/Axios";
import {PLATE_ORDER_TYPE_ID} from "../../configs/CakeConstants";

const fetchShapesSuccess = shapes => ({
    type: FETCH_SHAPES_SUCCESS,
    payload: shapes
})

export const fetchShapes =  () => {
    return async dispatch => {
        try {
            let shapes = await axiosRequest.get('/shapes', {
                params: {
                    filters: {
                        columns: [], operations: [], values: []
                    }, orderByColumns: {
                        columns: ['label'], directions: ['asc']
                    },
                }
            });

            dispatch(fetchShapesSuccess(shapes.data.map(data => {
                return {
                    value: data.id.toString(), label: data.label
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}

const fetchDoughTypesSuccess = doughTypes => ({
    type: FETCH_DOUGH_TYPES_SUCCESS,
    payload: doughTypes
})

export const fetchDoughTypes =  () => {
    return async dispatch => {
        try {
            let doughTypes = await axiosRequest.get('/dough-types', {
                params: {
                    filters: {
                        columns: [], operations: [], values: []
                    }, orderByColumns: {
                        columns: ['label'], directions: ['asc']
                    },
                }
            });
            dispatch(fetchDoughTypesSuccess(doughTypes.data.map(data => {
                return {
                    value: data.id.toString(), label: data.label
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}

const fetchPickupLocationsSuccess = pickupLocations => ({
    type: FETCH_PICKUP_LOCATIONS_SUCCESS,
    payload: pickupLocations
})

export const fetchPickupLocations =  () => {
    return async dispatch => {
        try {
            let pickupLocations = await axiosRequest.get('/pickup-locations', {
                params: {
                    filters: {
                        columns: [], operations: [], values: []
                    }, orderByColumns: {
                        columns: ['id'], directions: ['asc']
                    },
                }
            });
            dispatch(fetchPickupLocationsSuccess(pickupLocations.data.map(data => {
                return {
                    value: data.id.toString(), label: data.name
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}

const fetchMessageTypesSuccess = messageTypes => ({
    type: FETCH_MESSAGE_TYPES_SUCCESS,
    payload: messageTypes
})

export const fetchMessageTypes =  () => {
    return async dispatch => {
        try {
            let messageTypes = await axiosRequest.get('/message-types', {
                params: {
                    filters: {
                        columns: [], operations: [], values: []
                    }, orderByColumns: {
                        columns: ['label'], directions: ['asc']
                    },
                }
            });
            dispatch(fetchMessageTypesSuccess(messageTypes.data.map(data => {
                return {
                    value: data.id.toString(), label: data.label
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}

const fetchOrderTypesSuccess = orderTypes => ({
    type: FETCH_ORDER_TYPES_SUCCESS,
    payload: orderTypes
})

export const fetchOrderTypes =  () => {
    return async dispatch => {
        try {
            let orderTypes = await axiosRequest.get('/order-types', {
                params: {
                    filters: {
                        columns: ['id'], operations: ['!='], values: [PLATE_ORDER_TYPE_ID]
                    }, orderByColumns: {
                        columns: ['name'], directions: ['asc']
                    },
                }
            });
            dispatch(fetchOrderTypesSuccess(orderTypes.data.map(data => {
                return {
                    value: data.id.toString(), label: data.name
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}

const fetchProductTypesSuccess = productTypes => ({
    type: FETCH_PRODUCT_TYPES_SUCCESS,
    payload: productTypes
})

export const fetchProductTypes =  () => {
    return async dispatch => {
        try {
            let productTypes = await axiosRequest.get('/product-types', {
                params: {
                    filters: {
                        columns: [], operations: [], values: []
                    }, orderByColumns: {
                        columns: ['id'], directions: ['asc']
                    },
                }
            });
            dispatch(fetchProductTypesSuccess(productTypes.data.map(data => {
                return {
                    value: data.id.toString(), label: data.label
                }
            })));
        }
        catch(e){
            console.log(e)
        }
    }
}
