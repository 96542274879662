import React from "react";
import {Box, Button, Card, Center, Grid, Text} from "@mantine/core";
import Lottie from 'react-lottie-player'
import checkmark from "../../assets/lotties/checkmark.json"
import {Link} from "react-router-dom";
import {useStyles} from "../../util/CustomStyles";

/*
 * Page displayed after a successful order. Purely decorational
 */
export const OrderSuccess = () => {
    const {classes, cx} = useStyles();

    return (
        <Center sx={classes.full}>
            <Card
                shadow={"sm"}
                padding={"md"}
                sx={classes.successCard}
            >
                <Center>
                    <Box
                        component={Lottie}
                        loop={false}
                        animationData={checkmark}
                        play
                        sx={classes.lottie}
                    />
                </Center>
                <Text
                    size={"xl"}
                    align={"center"}
                >
                    Comandă înregistrată cu success!
                </Text>
                <Center
                    mt={10}
                >
                    <Link to='/home'>
                        <Button variant="outline" color="cyan" radius="lg" size="md" uppercase>
                            Înapoi Acasă
                        </Button>
                    </Link>
                </Center>
            </Card>
        </Center>
    )
}
