import React, {useEffect, useState} from "react";
import {
    Group,
    ActionIcon,
    Card,
    Center,
    Grid,
    LoadingOverlay,
    MultiSelect,
    Pagination,
    Table,
    Text,
    TextInput,
    ThemeIcon, Badge
} from "@mantine/core";
import {useStyles} from "../../util/CustomStyles";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {PickupLocations} from "../../configs/CakeConstants";
import {Link} from "react-router-dom";
import {IconEye, IconDownload, IconTrash} from "@tabler/icons";
import {DateRangePicker} from "@mantine/dates";
import {useSelector} from "react-redux";

export const Orders = () => {
    const filtersInitialState = {
        columns: [],
        operations: [],
        values: []
    }

    const {classes, cx} = useStyles();

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(filtersInitialState);
    const [total, setTotal] = useState(0);

    const pickupLocations = useSelector((state) => state.pickupLocations)

    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigate('/login');
        }
        axiosRequest.get('orders', {
            params: {
                page: page,
                perPage: 15,
                filters: filters,
                orderByColumns: {
                    columns: ['id'],
                    directions: ['desc'],
                },
            }
        }).then((response) => {
            setOrders(response.data.data)
            setTotal(response.data.pager.last_page)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        })
    }, [page, filters])

    const updateFilters = (column, operation, value) => {
        let newFilters = {...filters};
        const index = filters.columns.indexOf(column)
        if (index !== -1) {
            if (!value || !value.length || value[0] === "Invalid Date") {
                newFilters["columns"].splice(index, 1);
                newFilters["values"].splice(index, 1);
                newFilters["operations"].splice(index, 1);
                return setFilters(newFilters);
            }
            newFilters["values"][index] = value;
            return setFilters(newFilters);
        }

        newFilters["columns"].push(column);
        newFilters["operations"].push(operation);
        newFilters["values"].push(value);
        return setFilters(newFilters);
    }

    const downloadOrder = (id) => {
        const newOrders = orders.map(obj => {
            if (obj.id === id) {
                return {...obj, is_exported: true};
            }

            return obj;
        });
        setOrders(newOrders);

        axiosRequest.get("/orders/" + id + "/download", {
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'}),
                url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers.filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            window.open(url);
        }).catch(error => {
            console.log(error);
        })
    }

    const deleteOrder = (id) => {
        axiosRequest.delete("/orders/" + id ).then(response => {
            const newOrders = orders.filter(obj => obj.id !== id);
            setOrders(newOrders);
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <div style={{position: "relative"}}>
            <LoadingOverlay visible={loading}/>
            <Center sx={classes.full}>
                <Card
                    shadow={"sm"}
                    padding={"md"}
                    sx={classes.w90}
                >
                    <Text
                        size={"lg"}
                        transform={"uppercase"}
                        weight={700}
                    >
                        Filtre
                    </Text>
                    <Grid>
                        <Grid.Col lg={4} md={6}>
                            <Text>Nume client:</Text>
                            <TextInput onChange={(event) => updateFilters('client', 'like', event.target.value)}/>
                        </Grid.Col>
                        <Grid.Col lg={4} md={6}>
                            <Text>Locul ridicării comenzii:</Text>
                            <MultiSelect
                                id="orderType"
                                clearable
                                data={pickupLocations}
                                onChange={(value) => updateFilters('pickup_location_id', 'in', value)}
                            />
                        </Grid.Col>
                        <Grid.Col lg={4} md={6}>
                            <Text>Data ridicării comenzii:</Text>
                            <DateRangePicker
                                onChange={(value) => updateFilters('hour', 'between', [dayjs(value[0]).startOf('day').format('YYYY-MM-DD HH:mm'), dayjs(value[1]).endOf('day').format('YYYY-MM-DD HH:mm')])}
                            />
                        </Grid.Col>
                    </Grid>
                </Card>
            </Center>
            <Center sx={classes.full} mt={5}>
                <Card
                    shadow={"sm"}
                    padding={"md"}
                    sx={classes.w90}
                >
                    <Text
                        size={"lg"}
                        transform={"uppercase"}
                        weight={700}
                    >
                        Listă comenzi
                    </Text>
                    <Table highlightOnHover>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Client</th>
                            <th>Telefon</th>
                            <th>Dată</th>
                            <th>Locație ridicare</th>
                            <th>Status</th>
                            <th>Acțiuni</th>
                        </tr>
                        </thead>
                        <tbody>{orders.map((data) => (
                            <tr key={data.id}>
                                <td>{data.id}</td>
                                <td>{data.client}</td>
                                <td>{data.phone}</td>
                                <td>{dayjs(data.hour).format('DD-MM-YYYY HH:mm')}</td>
                                <td>{pickupLocations.find(location => location.value == data.pickup_location_id)?.label}</td>
                                <td>{data.is_exported ? <Badge>Printată</Badge> : <Badge color={"orange"}>În așteptare</Badge>}</td>
                                <td>
                                    <Group>
                                        <Link to={"/orders/" + data.id}>
                                            <ThemeIcon radius={"xl"} size={"lg"}>
                                                <IconEye/>
                                            </ThemeIcon>
                                        </Link>
                                        <ActionIcon variant={"filled"} onClick={() => downloadOrder(data.id)}
                                                    radius={"xl"} size={"lg"}>
                                            <IconDownload/>
                                        </ActionIcon>
                                        <ActionIcon variant={"filled"} onClick={() => deleteOrder(data.id)}
                                                    radius={"xl"} size={"lg"} color={"red"}>
                                            <IconTrash/>
                                        </ActionIcon>
                                    </Group>
                                </td>
                            </tr>
                        ))}</tbody>
                    </Table>
                    <Pagination position={"right"} page={page} onChange={setPage} total={total} mt={5}/>
                </Card>
            </Center>
        </div>
    )
}
