import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
        // Calendar General Styles
        cell: {
            border: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
        },

        day: {borderRadius: 0, height: 70, fontSize: theme.fontSizes.lg},

        weekday: {fontSize: theme.fontSizes.lg},

        weekdayCell: {
            fontSize: theme.fontSizes.xl,
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
            border: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
            height: 70,
        },


        //Calendar Styles based on modifiers
        outside: {
            opacity: 0,
        },

        weekend: {
            color: `${theme.colors.blue[6]}`,
        },

        selected: {
            backgroundColor: `${theme.colors.cyan[6]} !important`
        },

        //Scrollbar styling
        scrollBarRoot: {
            height: 500,

            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                height: 250
            },
        },

        //Successful Order Card Styling
        successCard: {
            width: "50%",

            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                width: "90%"
            },
        },

        //Lottie dimensions style
        lottie: {
            width: 400,
            height: 400
        },

        //General Width/Height Settings
        full: {
            width: "100%",
            height: "100%",
        },

        w90: {
            width: "90%"
        },

        //Position settings
        relative: {
            position: "relative"
        }
    }))
;
