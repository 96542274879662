import React, {useEffect, useState} from "react"
import {Calendar} from "@mantine/dates";
import dayjs from "dayjs";
import {useMantineTheme} from "@mantine/styles";
import {Box, LoadingOverlay, Text} from "@mantine/core";
import axiosRequest from "../../configs/Axios";
import {CalendarLegend} from "./calendarLegend";
import {useStyles} from "../../util/CustomStyles";

/*
 * Calendar component is used in the AddOrderPAge
 */
export const CalendarComponent = (props) => {
    const [occupiedDates, setOccupiedDates] = useState([]);
    const [month, setMonth] = useState(dayjs());
    const {classes, cx} = useStyles();
    const [loading, setLoading] = useState(false);

    const theme = useMantineTheme();

    /*
     * After each change of month, recheck for the availability of each date
     */
    useEffect(() => {
        if(!props.selectedOrderType)
            return;
        setLoading(true);
        axiosRequest.get('/order-types/' + props.selectedOrderType + '/month-capacity', {
            params: {
                filters: {
                    columns: ['year', 'month'],
                    operations: ['=', '='],
                    values: [dayjs(month).year(), dayjs(month).month() + 1]
                },
                orderByColumns: {
                    columns: ['day'],
                    directions: ['asc']
                },
            }
        }).then((response) => {
            setOccupiedDates(response.data);
        }).catch((error) => {
            console.log(error);
        })
        setLoading(false);
    }, [month, props.selectedOrderType])

    /*
     * Check if date is disabled in the calendar.
     * Disable Conditions:
     *  - Before Today
     *  - Fully booked
     *  - Not in selected month
     */
    const checkDateExcluded = (date) => {
        if (date < dayjs().subtract(1, 'day'))
            return true;
        let foundOccupancy = occupiedDates.findIndex(d => d.day === date.getDate());
        if (foundOccupancy >= 0 && occupiedDates[foundOccupancy].availability === 0)
            return true;
        if (date.getMonth() !== dayjs(month).month())
            return true;
        return false;
    }

    /*
     * Create the styling of each DayCell.
     * 0 Availability -> Red
     * 0 < Availability < MAX -> Yellow
     */
    const createDayStyle = (date) => {
        let foundOccupancy = occupiedDates.findIndex(d => d.day === date.getDate());
        if (foundOccupancy === -1 || date.getDate() < dayjs().date())
            return {};
        if (occupiedDates[foundOccupancy].availability === 0)
            return {backgroundColor: theme.colors.red[5], color: theme.white}
        return {backgroundColor: theme.colors.yellow[5], color: theme.white}
    }

    return (
        <Box component={"div"} sx={classes.relative}>
            <LoadingOverlay visible={loading}/>
            <div>
                <Text
                    size={"md"}
                >
                    Alege o zi:
                </Text>
                {
                    props.selectedOrderType ?
                        <>
                            <Calendar
                                allowLevelChange={false}
                                locale={"ro"}
                                size="xl"
                                fullWidth
                                value={props.selectedDay}
                                onChange={props.setSelectedDay}
                                onMonthChange={setMonth}
                                excludeDate={checkDateExcluded}
                                dayStyle={createDayStyle}
                                classNames={{
                                    cell: classes.cell,
                                    day: classes.day,
                                    weekday: classes.weekday,
                                    weekdayCell: classes.weekdayCell
                                }}
                                dayClassName={(date, modifiers) =>
                                    cx({
                                        [classes.outside]: modifiers.outside,
                                        [classes.weekend]: modifiers.weekend,
                                        [classes.selected]: modifiers.selected,
                                    })
                                }
                            />
                            <CalendarLegend />
                        </> : <></>
                }
            </div>
        </Box>
    )
}
