const dev = {
    API_ENDPOINT_URL: 'https://programari-api.torturi-iasi.ro/api'
};

const prod = {
    API_ENDPOINT_URL: 'https://programari-api.torturi-iasi.ro/api'
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()
