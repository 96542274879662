import React, {useEffect, useState} from "react";
import {Grid, Select, Text, TextInput, Card, Button, Group, NumberInput, Input, FileInput, Image} from "@mantine/core";
import {CAKE_TYPE_ID, PLATE_ORDER_TYPE_ID, ProductStructure} from "../../configs/CakeConstants";
import {useSelector} from "react-redux";

export const ProductCard = (props) => {

    const [product, setProduct] = useState(ProductStructure)
    const [doughError, setDoughError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [messageTypeError, setMessageTypeError] = useState("");
    const [shapeError, setShapeError] = useState("");
    const [productTypeError, setProductTypeError] = useState("");

    const messageTypes = useSelector((state) => state.messageTypes)
    const shapes = useSelector((state) => state.shapes)
    const doughTypes = useSelector((state) => state.doughTypes)
    const productTypes = useSelector((state) => state.productTypes)

    useEffect(() => {
        setProduct(props.product);
    }, [props.product])

    const saveImage = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setProduct({...product, image: reader.result})
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const save = () => {
        if (!validate())
            return
        props.updateProduct(product);
    }

    const validate = () => {
        setProductTypeError("");
        setDoughError("");
        setShapeError("");
        setTypeError("");
        setMessageTypeError("");
        if (!product.product_type_id) {
            setDoughError("Trebuie selectat tipul produsului!")
            return false;
        }
        if (product.product_type_id != CAKE_TYPE_ID) {
            return true;
        }
        if (!product.dough_type_id) {
            setDoughError("Trebuie selectat tipul aluatului!")
            return false;
        }
        if (!product.shape_id) {
            setShapeError("Trebuie selectată forma produsului!");
            return false;
        }
        if (!product.order_type_id) {
            setTypeError("Trebuie selectat tipul produsului!");
            return false;
        }
        if (product.message?.length && !product.message_type_id) {
            setMessageTypeError("Trebuie selectat tipul aplicării mesajului!");
            return false;
        }
        return true;
    }

    return (
        <Card shadow={"sm"} p={"lg"} mb={10}>
            <Text
                size={"lg"}
                transform={"uppercase"}
                weight={700}
            >
                {"Produs " + (props.index + 1)}
            </Text>
            {
                props.editableId === props.index ?
                    <>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Nume produs*</Text>
                            <Input.Wrapper
                                id="name"
                                required
                                onChange={(event) => setProduct({...product, name: event.target.value})}
                            >
                                <TextInput id="name" value={product.name}/>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Tipul produsului*</Text>
                            <Select
                                id="productType"
                                data={productTypes}
                                error={productTypeError}
                                value={product.product_type_id}
                                onChange={(value) => {
                                    if(value != CAKE_TYPE_ID){
                                        return setProduct({...product, order_type_id: PLATE_ORDER_TYPE_ID, product_type_id: value})
                                    }
                                    return setProduct({...product, product_type_id: value})
                                }}
                            />
                        </Grid.Col>
                        {
                            product.product_type_id == CAKE_TYPE_ID ?
                                <>
                                    <Grid.Col
                                        span={12}
                                    >
                                        <Text>Tipul tortului*</Text>
                                        <Select
                                            id="orderType"
                                            data={props.orderTypes}
                                            error={typeError}
                                            value={product.order_type_id}
                                            onChange={(value) => {
                                                return setProduct({...product, order_type_id: value})
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <Text>Tipul blatului*</Text>
                                        <Select
                                            id="doughType"
                                            data={doughTypes}
                                            value={product.dough_type_id}
                                            error={doughError}
                                            onChange={(value) => setProduct({...product, dough_type_id: value})}
                                        />
                                    </Grid.Col>
                                    <Grid.Col
                                        span={12}
                                    >
                                        <Text>Forma produsului*</Text>
                                        <Select
                                            id="shape"
                                            data={shapes}
                                            value={product.shape_id}
                                            error={shapeError}
                                            onChange={(value) => setProduct({...product, shape_id: value})}
                                        />
                                    </Grid.Col>
                                    <Grid.Col
                                        span={12}
                                    >
                                        <Text>Mesaj</Text>
                                        <TextInput id="message" value={product.message} onChange={(event) => {
                                            if (!event.target.value.length) {
                                                return setProduct({...product, messageType: null, message: ""});
                                            }
                                            setProduct({...product, message: event.target.value})
                                        }}/>
                                    </Grid.Col>
                                    <Grid.Col
                                        span={12}
                                    >
                                        <Text>Aplicare mesaj</Text>
                                        <Select
                                            id="messageType"
                                            disabled={!product.message}
                                            data={messageTypes}
                                            error={messageTypeError}
                                            value={product.message_type_id}
                                            onChange={(value) => setProduct({...product, message_type_id: value})}
                                        />
                                    </Grid.Col>
                                </>
                                :
                                <Grid.Col
                                    span={12}
                                >
                                    <Text>Cantitate per platou(kg)</Text>
                                    <NumberInput
                                        id="quantity_per_plate"
                                        value={product.quantity_per_plate}
                                        precision={2}
                                        step={0.5}
                                        min={0}
                                        decimalSeparator=","
                                        onChange={(value) => setProduct({...product, quantity_per_plate: value})}
                                    />
                                </Grid.Col>
                        }
                        <Grid.Col
                            span={12}
                        >
                            <Text>Cantitate (kg)</Text>
                            <NumberInput
                                id="quantity"
                                value={product.quantity}
                                precision={2}
                                step={0.5}
                                min={0}
                                decimalSeparator=","
                                onChange={(value) => setProduct({...product, quantity: value})}
                            />
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Pret (lei)</Text>
                            <NumberInput
                                id="price"
                                value={product.price}
                                precision={2}
                                step={0.5}
                                min={0}
                                decimalSeparator=","
                                onChange={(value) => setProduct({...product, price: value})}
                            />
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Alte observații</Text>
                            <Input.Wrapper
                                id="details"
                                required
                                onChange={(event) => setProduct({...product, details: event.target.value})}
                            >
                                <TextInput id="details" value={product.details}/>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                        >
                            <Text>Imagine</Text>
                            <FileInput
                                accept="image/png,image/jpeg"
                                onChange={(file) => saveImage(file)}
                            />
                        </Grid.Col>
                    </> :
                    <Grid>
                        <Grid.Col lg={4} sm={6}>
                            <Text
                                size={"md"}
                                weight={500}
                            >
                                {"Nume: " + product.name}
                            </Text>
                        </Grid.Col>
                        <Grid.Col lg={4} sm={6}>
                            <Text
                                size={"md"}
                                weight={500}
                            >
                                {"Tip: " + productTypes.find(type => type.value == product.product_type_id)?.label ?? "-"}
                            </Text>
                        </Grid.Col>
                        {
                            product.product_type_id == CAKE_TYPE_ID ?
                                <>
                                    <Grid.Col lg={4} sm={6}>
                                        <Text
                                            size={"md"}
                                            weight={500}
                                        >
                                            {"Tip tort: " + (props.orderTypes.find(orderType => orderType.value === product.order_type_id)?.label ?? "-")}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col lg={4} sm={6}>
                                        <Text
                                            size={"md"}
                                            weight={500}
                                        >
                                            {"Blat: " + (doughTypes.find(dough => dough.value === product.dough_type_id)?.label ?? "-")}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col lg={4} sm={6}>
                                        <Text
                                            size={"md"}
                                            weight={500}
                                        >
                                            {"Forma: " + (shapes.find(shape => shape.value === product.shape_id)?.label ?? "-")}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col lg={4} sm={6}>
                                        <Text
                                            size={"md"}
                                            weight={500}
                                        >
                                            {"Mesaj: " + product.message ?? "-"}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col lg={4} sm={6}>
                                        <Text
                                            size={"md"}
                                            weight={500}
                                        >
                                            {"Tip mesaj: " + (messageTypes.find(messageType => messageType.value === product.message_type_id)?.label ?? "Fară")}
                                        </Text>
                                    </Grid.Col>
                                </>
                                :
                                <Grid.Col lg={4} sm={6}>
                                    <Text
                                        size={"md"}
                                        weight={500}
                                    >
                                        {"Cantitate per platou: " + (product.quantity_per_plate ? product.quantity_per_plate + "(kg)" : "-")}
                                    </Text>
                                </Grid.Col>
                        }
                        <Grid.Col lg={4} sm={6}>
                            <Text
                                size={"md"}
                                weight={500}
                            >
                                {"Pret: " + (product.price ? product.price + "(lei)" : "-")}
                            </Text>
                        </Grid.Col>
                        <Grid.Col lg={4} sm={6}>
                            <Text
                                size={"md"}
                                weight={500}
                            >
                                {"Cantitate: " + (product.quantity ? product.quantity + "(kg)" : "-")}
                            </Text>
                        </Grid.Col>
                        <Grid.Col lg={6} sm={6}>
                            <Text
                                size={"md"}
                                weight={500}
                            >
                                {"Alte observaziții: " + (product.details ?? "-")}
                            </Text>
                        </Grid.Col>
                        <Grid.Col lg={12} sm={12}>
                            <Text
                                size={"md"}
                                weight={500}
                            >Imagine:</Text>
                            { product.image ? <Image src={product.image} height={400} fit="contain"/> : "" }
                        </Grid.Col>
                    </Grid>
            }
            <Grid.Col span={12} style={{float: "right"}}>
                <Group>
                    {
                        props.index ?
                            <Button
                                color={"red"}
                                disabled={props.editableId != null && props.editableId !== props.index}
                                onClick={() => {
                                    props.delete()
                                }}>
                                Sterge
                            </Button> : ""
                    }
                    {
                        props.editableId === props.index ?
                            <Button onClick={() => {
                                save()
                            }}>
                                Salveaza
                            </Button>
                            :
                            <Button
                                disabled={props.editableId != null && props.editableId !== props.index}
                                onClick={() => {
                                    props.setEditable()
                                }}>
                                Editeaza
                            </Button>
                    }
                </Group>
            </Grid.Col>
        </Card>
    )
}
