export const DEFAULT_REDIRECT =  '/home';

export const USER_TYPES = [
    {
        admin: {
            label: "admin",
            redirect: "/orders"
        },
    }
]
