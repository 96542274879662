import React from "react";
import {Button} from "@mantine/core";
import {Link} from "react-router-dom";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";

export const AdminMenu = () => {
    let navigate = useNavigate();

    const logout = () => {
        axiosRequest.post('/login')
            .then((response) => {
                localStorage.removeItem('user');
                navigate('/login')
            })
            .catch((error) => {
                localStorage.removeItem('user');
                navigate('/login')
            })
    }

    return (
        <>
            <Link to='/orders'>
                <Button variant="outline" color="cyan" radius="lg" size="md" uppercase>
                    Comenzi
                </Button>
            </Link>
            <Button onClick={logout} variant="outline" color="cyan" radius="lg" size="md" ml={5} uppercase>
                Deconectare
            </Button>
        </>
    )
}
