import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Center,
    Grid,
    Input,
    Group,
    Text,
    Stepper,
} from "@mantine/core";
import axiosRequest from "../../configs/Axios";
import {useNavigate} from "react-router";
import {useStyles} from "../../util/CustomStyles";
import SignatureCanvas from 'react-signature-canvas'
import {CakeDetailsForm} from "../../components/cakeDetailsForm";
import {OrderSummary} from "../../components/orderSummary";

/*
 * Most important page. Creates a new order
 */
export const AddOrder = () => {
    const {classes, cx} = useStyles();
    const [signatureRef, setSignatureRef] = useState({});

    const [order, setOrder] = useState();

    const [active, setActive] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [orderErrors, setOrderErrors] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        if (order?.client) {
            nextStep();
        }
    }, [order])

    const nextStep = () => {
        switch (active) {
            case 0:
                break;
            default:
                setActive((current) => (current < 2 ? current + 1 : current));
        }
        setActive((current) => (current < 2 ? current + 1 : current));
    }
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    /*
     * Sends data to post the order at the API
     */
    const submitForm = () => {
        setButtonLoading(true);
        axiosRequest.post('/orders', {
            ...order,
            order_type_id: order.products[0].order_type_id,
            signature: signatureRef.getTrimmedCanvas().toDataURL('image/png'),
        }).then((response) => {
            setButtonLoading(false);
            navigate("/order-success")
        }).catch((error) => {
            setButtonLoading(false);
        })
    }

    return (
        <div style={{position: "relative"}}>
            <Center sx={classes.full}>
                <Card
                    shadow={"sm"}
                    padding={"md"}
                    sx={classes.w90}
                >
                    <Text
                        size={"lg"}
                        transform={"uppercase"}
                        weight={700}
                    >
                        Adaugă o comanda nouă
                    </Text>
                    <Stepper active={active} onStepClick={setActive} breakpoint="sm" mt={"4"}>
                        <Stepper.Step label="Detalii Tort" allowStepSelect={false}>
                            <CakeDetailsForm
                                setOrder={setOrder}
                            />
                        </Stepper.Step>
                        <Stepper.Step label="Semnatură" allowStepSelect={false}>
                            <Grid>
                                <Grid.Col span={12}>
                                    {
                                        order ? <OrderSummary key={order} order={order}/> : ""
                                    }
                                    <Text
                                        size={"lg"}
                                        transform={"uppercase"}
                                        weight={700}
                                    >
                                        Semnați aici:
                                    </Text>
                                    <SignatureCanvas penColor='black'
                                                     ref={(ref) => setSignatureRef(ref)}
                                                     canvasProps={{
                                                         width: 1000,
                                                         height: 400,
                                                         className: 'sigCanvas'
                                                     }}/>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Input.Wrapper
                                        error={orderErrors}
                                    >
                                        <Group position="center" mt="xl">
                                            <Button onClick={submitForm}
                                                    loading={buttonLoading}>Trimite</Button>
                                        </Group>
                                    </Input.Wrapper>
                                </Grid.Col>
                            </Grid>
                        </Stepper.Step>
                    </Stepper>
                </Card>
            </Center>
        </div>
    )
}

